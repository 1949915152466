<template>
  <div class="tabulation">
    <el-card class="chart-card">
      <!-- 搜索框 -->
      <div class="grabble">
        <div>
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="large"
            @click="append"
            ><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button
          >
        </div>
      </div>
      <el-table
        :data="filterTableData"
        style="width: 100%"
        row-key="unionId"
        @cell-mouse-enter="obtain"
        border
        :header-cell-style="{ background: '#F5F7FA' }"
        :empty-text="texts"
      >
      <!-- 方案名称 -->
        <el-table-column :show-overflow-tooltip='true' align="center" label="分类名称" width="250" prop="name" />
        <!-- 主题图片 -->
        <!-- <el-table-column
          label="主题图片"
          prop="imageUrl"
          width="350"
          align="center"
        >
        <template #default="scope">
          <el-image class="table-td-thumb" :src="scope.row.imageUrl" :preview-src-list="[scope.row.imageUrl]"  preview-teleported="true">
          </el-image>
        </template>
        </el-table-column> -->
        <!-- 时间 -->
        <el-table-column
          :show-overflow-tooltip='true'
          label="创建时间"
          prop="createTime"
          align="center"
          :formatter="tiems"
        >
        </el-table-column>
         <!-- 是否发布 -->
        <el-table-column :show-overflow-tooltip='true' align="center" :formatter="reach" label="是否发布" prop="status" />
        <el-table-column label="操作" align="center" width="340">
          <template #default="scope">
            <el-button
              size="default"
              @click="copyreader(scope.row.classifyId)"
              class="bulur"
              ><el-icon class="cancel"> <EditPen /> </el-icon>编辑</el-button
            >
            <el-button
              size="default"
              type="success"
              @click="handleEdit(scope.row.classifyId)"
              class="green"
            >
              <el-icon class="cancel">
                <Setting />
              </el-icon>
              活动管理</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 表格分页 -->
      <!-- 一页时候隐藏 hide-on-single-page="true" -->
      <!-- <el-pagination
        layout="prev, pager, next"
        :current-page="currentPage" 
        :page-size="pagesize"
        :total="filterTableData.length"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      /> -->
    </el-card>
  </div>
</template>
<script>
import { EditPen,Plus,Setting} from "@element-plus/icons-vue";
import { useRouter,useRoute  } from "vue-router";
import { Classify } from "../../../utils/api"
import { ref,onMounted,onActivated } from 'vue'
import moments from 'moment'
import qs from 'qs'
// import { ElMessage } from "element-plus";
export default {
  name:"classify",
  setup() {
    const route = useRoute();
    // 是否发布
    let reach=(row, column, cellValue)=>{
      if (cellValue == 1) {
        return "是";
      } else if (cellValue === 2) {
        return "否";
      }
    }
    // 加载中提示
    let texts=ref('加载中')
    // 提示内容
    let price=ref('')
    // 时间转换
    let tiems=(row, colum,cellValue )=>{
      if(typeof cellValue=='number'){
        return moments(cellValue*1000).format().substring(0,10)
      }
    }
    // 获取典型列表
    let translate=ref(2)
    let translate1=ref(2)
    onActivated(()=>{
      translate.value=route.params.userId
      translate1.value=route.params.UserId
      if(translate.value==1){
        texts.value='加载中'
        list()
      }else if(translate1.value==1){
        texts.value='加载中'
        list()
      }
      // console.log(translate.value)
    })
    onMounted(()=>{
      list()
    })
    let list =()=>{
      let data= {
        schemeId:sessionStorage.getItem('modesids')
      }
      Classify(qs.stringify(data)).then((res)=>{
        filterTableData.value=res.data.data.classifyList
        if(filterTableData.value.length==0){
          texts.value='暂无数据'
          console.log('空了')
        }
        console.log(res)
      })
    }
    // 管理跳转
    const handleEdit = (id)=>{
      sessionStorage.setItem('classifyIdss', id)
      router.push({ name: 'activity', params: { UserId: 1 }});
      // router.push('/activity')
    }
    // 编辑跳转
    const copyreader = (id)=>{
      console.log(id)
      sessionStorage.setItem('classifyId', id)
      router.push({ name: 'editclassify', params: { UserId: 1 }});
      // router.push('/editclassify')
    }
    // 路由跳转
    const router = useRouter()
    // 头部添加跳转
    let append=()=>{
      router.push('/Addclassify')
    }
    // 列表数据循环
    const filterTableData =ref([])
    return {
      reach,
      texts,
      // 默认提示内容
      price,
      // 时间转换
      tiems,
      // 管理跳转
      handleEdit,
      // 编辑跳转
      copyreader,
      // 头部组织添加跳转
      append,
      // 循环列表数据
      filterTableData
    };
  },
  components: {
    EditPen,
    Plus,
    Setting
  },
};
</script>
<style lang="scss" scoped>
.designation{
  margin-right: 10px;
}
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
.chart-card{
  height: calc(100vh - 145px);
  overflow: auto;
}
::v-deep  .el-table__empty-block{
  border-bottom: 1px solid #EBEEF5 !important;
}
::v-deep .el-textarea__inner{
  height: 110px;
}
::v-deep .dsa{
  margin-left: 10px !important;
}
::v-deep .asd{
  margin-right: 10px !important;
}
.el-button+.el-button{
  margin-left: 0px;
}
.green {
  color: #67c23a;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
  --el-button-active-bg-color:none;
}
.green:hover {
  color: #b3e19d;
}
::v-deep .el-table__body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-table__header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.hunt {
  width: 36.5%;
}
.grabble {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}
.el-table__header {
  margin-top: 0;
  margin-bottom: 0;
}
.red {
  color: red;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.red:hover {
  color: #fab6b6;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
.el-scrollbar__thumb {
  background-color: #ffffff;
}
.items {
  display: flex;
}
.el-select .el-input__inner {
  width: 183px;
}
::v-deep .el-dialog--center .el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-select-dropdown__empty {
  display: none !important  ;
}
.el-select-dropdown__item {
  background-color: #ffffff;
  height: 40px !important;
}
.el-select-dropdown__item {
  overflow: visible;
}
.labor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tianjia {
  margin-left: 20px;
}
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
.cancelgu {
  margin-right: 6px;
  font-weight: bold;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
::v-deep .el-card__body {
  padding: 0;
}
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
::v-deep .el-scrollbar__thumb {
  display: none !important;
}
</style>
<style>
.el-popper {
  z-index: 10000 !important;
}
.modality {
  height: 150px !important;
}
/* .el-scrollbar__thumb {
  display: none !important;
} */
.el-select-dropdown__item.selected {
  font-weight: 500 !important;
}
</style>
